// 加入我们 加入蓝亚
<template>
  <div class="join-us">
    <Bigimg
      title="风雨同舟·共同成长"
      subTitle="团建、奋进、求实、创新、服务客户、发展企业、造福员工、回报社会"
      :imgUrl="require('@/assets/images/spfw.jpg')"
    ></Bigimg>
    <div class="content-wrapper">
      <div class="fuli wrap-v1">
        <round-text
          :text="lang === 'zh-CN' ? fuli.cntext : fuli.entext"
          :style="{ fontSize: (lang === 'en' ? 22 : 28) + 'px' }"
          :color="fuli.color"
          v-for="(fuli, index) in fulis"
          :key="index"
        ></round-text>
      </div>
      <div class="img-wrapper wrap-v1">
        <img src="../assets/images/tjhd.jpg" alt="" />
      </div>
      <div class="title">
        加入我们，一起预见未来
      </div>
      <div class="table-wrapper wrap-v1">
        <el-table :data="tableData">
          <el-table-column prop="a" label="职位名称" align="center" />
          <el-table-column prop="b" label="职位类别" align="center" />
          <el-table-column prop="c" label="工作地点" align="center" />
          <el-table-column prop="d" label="发布时间" align="center" />
          <el-table-column prop="e" label="招募人数" align="center" />
          <el-table-column label="发起申请" align="center">
            <template>
              <el-button type="text" @click="goDetail()">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import roundText from "@/components/round-text.vue";
import { mapState } from "vuex";
import Bigimg from "@/components/bigimg.vue";
export default {
  components: { roundText, Bigimg },
  computed: {
    ...mapState(["lang"])
  },
  data() {
    return {
      fulis: [
        { entext: "Social Insurance", cntext: "社保", color: "#4ECA7A" },
        {
          entext: "Unemployment Insurance",
          cntext: "失业险",
          color: "#4DC8B7"
        },
        { entext: "Pension", cntext: "养老保险", color: "#6A8BE9" },
        { entext: "Work Injury Insurance", cntext: "工伤险", color: "#9C88F2" },
        {
          entext: "Transportation Expenses",
          cntext: "交通费",
          color: "#B4C84D"
        },
        { entext: "Training", cntext: "培训", color: "#5DD0A9" },
        { entext: "Weekend", cntext: "双休", color: "#88BDF2" },
        { entext: "Year-end Awards", cntext: "年终奖", color: "#5DB7D0" },
        { entext: "Team Building", cntext: "团建", color: "#A978CB" },
        { entext: "Paid Leave", cntext: "带薪休假", color: "#E5BE51" }
      ],
      tableData: [
        {
          a: "嵌入式软件开发工程师",
          b: "研发类",
          c: "深圳",
          d: "2021/5/21",
          e: "2"
        },
        {
          a: "后端研发工程师（java）",
          b: "研发类",
          c: "深圳",
          d: "2021/5/21",
          e: "2"
        },
        {
          a: "前端研发工程师（vue）",
          b: "研发类",
          c: "深圳",
          d: "2021/5/21",
          e: "2"
        },
        {
          a: "售前商务",
          b: "营销类",
          c: "深圳",
          d: "2021/5/21",
          e: "2"
        }
      ]
    };
  },
  methods: {
    goDetail() {
      return;
      this.$router.push({ name: "positionDetails" });
    }
  }
};
</script>
<style scoped lang="scss">
.content-wrapper {
  padding: 125px 0 125px;
  .fuli {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 75px;
    justify-items: center;
  }
  .img-wrapper {
    margin-top: 75px;
    margin-bottom: 105px;
    img {
      width: 100%;
    }
  }
  .title {
    margin-bottom: 45px;
    font-size: 50px;
    color: #333333;
    line-height: 70px;
  }
}
</style>
