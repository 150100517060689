<template>
  <div class="round-text" :style="{ color: color }">
    {{ text }}
  </div>
</template>
<script>
export default {
  props: {
    text: "",
    color: ""
  }
};
</script>
<style scoped lang="scss">
.round-text {
  height: 140px;
  width: 140px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  border: 10px solid;
  border-radius: 50%;
}
</style>
